import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import api from '../axiosConfig';
import Swal from 'sweetalert2';

const PostEditor = ({ onSave, onCancel, initialData, projectId }) => {

  const effectiveProjectId = initialData?.projectId || projectId;

  const [title, setTitle] = useState(initialData ? initialData.title : '');
  const [content, setContent] = useState(initialData ? initialData.content : '');
  const [uploadedFiles, setUploadedFiles] = useState([]); // Para novos uploads via Dropzone
  const [progress, setProgress] = useState(initialData ? initialData.progress : 0);
  const [filesToDelete, setFilesToDelete] = useState([]); // Arquivos existentes a serem excluídos
  const [currentFiles, setCurrentFiles] = useState([]); // Arquivos já carregados no servidor

  // Carrega os arquivos existentes se estiver em modo de edição
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await api.get(`/projects/${effectiveProjectId}/posts/${initialData._id}/files`);
        setCurrentFiles(response.data || []); // Garantir que o valor padrão seja um array.
      } catch (error) {
        console.error('Erro ao carregar arquivos do post:', error);
      }
    };
  
    if (initialData && initialData._id) {
      fetchFiles();
    }
  }, [initialData, effectiveProjectId]);
  

  // Gerencia novos arquivos via Dropzone
  const onDrop = (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map((file) => ({
      id: uuidv4(),
      preview: URL.createObjectURL(file),
      file,
    }));
    setUploadedFiles([...uploadedFiles, ...filesWithPreview]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Remove um arquivo da lista de novos uploads
  const handleRemoveFile = (fileId) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.id !== fileId));
  };

  // Remove arquivos já existentes com confirmação de SweetAlert
  const handleRemoveExistingFile = async (fileName) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: `Você realmente deseja excluir o arquivo "${fileName}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/projects/${effectiveProjectId}/posts/${initialData._id}/files`, { data: { fileName } });
          setCurrentFiles(currentFiles.filter(file => file !== fileName));
          setFilesToDelete([...filesToDelete, fileName]); // Adiciona o arquivo à lista de exclusão
          Swal.fire('Excluído!', `O arquivo "${fileName}" foi excluído.`, 'success');
        } catch (error) {
          console.error('Erro ao deletar arquivo:', error);
          Swal.fire('Erro!', 'Ocorreu um erro ao excluir o arquivo.', 'error');
        }
      }
    });
  };

// Função para salvar o post e os arquivos associados
const handleSave = async () => {
  // Exibir animação de carregamento
  Swal.fire({
    title: 'Salvando...',
    text: 'Por favor, aguarde enquanto o post é salvo.',
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => {
      Swal.showLoading();
    },
  });

  const formData = new FormData();
  formData.append('title', title);
  formData.append('content', content);
  formData.append('progress', progress);

  // Adiciona os novos arquivos que foram carregados
  uploadedFiles.forEach((file) => {
    formData.append('files', file.file);
  });

  // Adiciona a lista de arquivos a serem excluídos
  filesToDelete.forEach((fileName) => {
    formData.append('filesToDelete', fileName);
  });

  // Usa o projectId diretamente passado como prop
  formData.append('projectId', effectiveProjectId);

  try {
    await onSave(formData); // Enviar o formData ao backend

    // Fecha o alerta de carregamento e mostra sucesso
    Swal.fire({
      icon: 'success',
      title: 'Post salvo!',
      text: 'O post foi salvo com sucesso.',
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    console.error('Erro ao salvar post:', error);
    // Fecha o alerta de carregamento e mostra erro
    Swal.fire({
      icon: 'error',
      title: 'Erro',
      text: 'Ocorreu um erro ao salvar o post.',
    });
  }
};


  return (
    <div className="p-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Título do Post</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Digite o título do post..."
          className="w-full p-2 border rounded bg-white"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Conteúdo do Post</label>
        <ReactQuill
          value={content}
          onChange={setContent}
          placeholder="Escreva o conteúdo do post..."
          className="w-full p-2 border rounded bg-white"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Progresso do Projeto</label>
        <div className="relative w-full h-2 rounded bg-gray-300">
          <div
            className="absolute top-0 left-0 h-full rounded bg-base"
            style={{ width: `${`${progress}${progress.toString().endsWith('%') ? '' : '%'}`}` }}
          ></div>
          <input
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={(e) => setProgress(e.target.value)}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
        </div>
        <div className="text-center text-gray-700 mt-2">
          {`${progress}${progress.toString().endsWith('%') ? '' : '%'}`}
        </div>
      </div>

      {/* Dropzone para novos arquivos */}
      <div {...getRootProps()} className="p-4 border-dashed border-2 border-gray-300 rounded mb-4 cursor-pointer">
        <input {...getInputProps()} />
        <p className="text-center text-gray-600">
          Arraste e solte arquivos aqui, ou clique para selecionar arquivos
        </p>

        {/* Lista de novos arquivos a serem carregados */}
        {uploadedFiles.length > 0 && (
          <ul className="mt-4 space-y-2">
            {uploadedFiles.map((file) => (
              <li key={file.id} className="flex justify-between items-center p-2 border border-gray-200 rounded bg-white">
                <span>{file.file.name}</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFile(file.id);
                  }}
                  className="text-red-500 hover:text-red-700"
                  title="Remover"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Lista de arquivos já carregados */}
      {currentFiles.length > 0 && (
        <div className="mt-4">
          <h4 className="font-bold mb-2">Arquivos Anexados</h4>
          <ul className="space-y-2">
            {currentFiles.map((fileName) => (
              <li key={fileName} className="flex justify-between items-center p-2 border border-gray-200 rounded bg-white">
                <span>{fileName}</span>
                <button
                  onClick={() => handleRemoveExistingFile(fileName)}
                  className="text-red-500 hover:text-red-700"
                  title="Remover"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="flex justify-end mt-4">
        <button
          onClick={onCancel}
          className="bg-gray-500 text-white font-bold py-2 px-4 rounded mr-2 hover:bg-gray-700 transition duration-200 ease-in-out"
        >
          Cancelar
        </button>
        <button
          onClick={handleSave}
          className="bg-base text-white font-bold py-2 px-4 rounded hover:bg-hover transition duration-200 ease-in-out"
        >
          Salvar Post
        </button>
      </div>
    </div>
  );
};

export default PostEditor;
