// src/axiosConfig.js
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

// Defina a URL base a partir da variável de ambiente
//const API_BASE_URL = 'http://localhost:3001/api';
const API_BASE_URL = 'https://api.agah.arq.br/api';

// Variável para armazenar o token na memória
let authToken = '';

// Função para configurar o token manualmente
export const setAuthToken = (token) => {
  authToken = token; // Armazena o token na memória
  if (token) {
    api.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers['Authorization'];
  }
};

// Crie uma função para retornar a configuração correta do axios
const createApiInstance = () => {
  return axios.create({
    baseURL: API_BASE_URL
  });
};

// Configurando a instância do Axios
const api = createApiInstance();

api.interceptors.request.use(
  (config) => {
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Configurando o interceptor do Axios para tratamento de erros de autenticação
api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;

      // Verifica se a requisição foi uma tentativa de login
      const isAuthRequest = originalRequest.url.includes('/authenticate');

      if (!isAuthRequest) {
        // Se o token estiver expirado ou inválido (e não for uma tentativa de login), exiba o popup
        const result = await MySwal.fire({
          title: 'Sessão expirada',
          text: 'Sua sessão expirou, por favor, faça login novamente.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Login',
          allowOutsideClick: false
        });

        if (result.isConfirmed) {
          // Redirecionar ou abrir o modal de login
          window.location.href = '/painel'; // Ou exibir um modal de login
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;