import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ListComponentArmazenamento from './ListComponentArmazenamento';
import api from '../axiosConfig';
import ModalBase from './ModalBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const PainelArmazenamento = () => {
  const [arquivos, setArquivos] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]); // Lista de arquivos para upload via dropzone
  const [directoryName, setDirectoryName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isCreatingDirectory, setIsCreatingDirectory] = useState(false);
  const [currentPath, setCurrentPath] = useState('agah-arquitetura/private/'); // Caminho atual
  const [isLoading, setIsLoading] = useState(false); // Indica se o upload está em progresso

  useEffect(() => {
    fetchArquivos();
  }, [currentPath]); // Atualiza sempre que o caminho mudar

  const fetchArquivos = async () => {
    // Limpa a listagem antes de buscar novos arquivos
    setArquivos([]);
    
    try {
      const response = await api.get('/storage/files');
      const arquivosFiltrados = response.data
        .filter(item => item.Key.startsWith(currentPath) && item.Key !== currentPath)
        .map(item => ({
          nome: item.Key.replace(currentPath, '').replace(/\/$/, ''), // Remove a barra final
          isDirectory: item.Key.endsWith('/')
        }))
        .filter(item => !item.nome.includes('/')); // Exclui arquivos e pastas de subdiretórios
  
      setArquivos(arquivosFiltrados);
    } catch (error) {
      console.error('Erro ao buscar arquivos:', error);
    }
  };  

  // Função para remover arquivos da lista da dropzone
  const handleRemoveFile = (fileIndex, e) => {
    e.stopPropagation(); // Impede que o evento clique seja propagado para a dropzone
    const updatedFiles = filesToUpload.filter((_, index) => index !== fileIndex);
    setFilesToUpload(updatedFiles);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (filesToUpload.length === 0) return;
    
    setIsLoading(true); // Inicia o carregamento
    try {
      const uploadPromises = filesToUpload.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', currentPath); // Envia o caminho junto com o arquivo
        return api.post('/storage/upload', formData);
      });

      await Promise.all(uploadPromises);
      setIsLoading(false); // Termina o carregamento
      fetchArquivos();
      setFilesToUpload([]);
      setShowModal(false);
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
      setIsLoading(false); // Termina o carregamento em caso de erro
    }
  };

  const handleDirectoryCreation = async (e) => {
    e.preventDefault();
    if (!directoryName) return;

    const fullDirectoryPath = `${currentPath}${directoryName}/`;

    try {
      await api.post('/storage/create-directory', { directoryName: fullDirectoryPath });
      fetchArquivos();
      setDirectoryName('');
      setShowModal(false);
    } catch (error) {
      console.error('Erro ao criar diretório:', error.response || error.message);
    }
  };

  const handleDownload = async (item) => {
    try {
      const response = await api.get(`/storage/download?fileName=${encodeURIComponent(currentPath + item.nome)}`, {
        responseType: 'blob' // Importante para baixar o arquivo
      });
  
      // Cria um link temporário para o download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', item.nome); // Nome do arquivo a ser baixado
      document.body.appendChild(link);
      link.click();
      link.remove(); // Remove o link após o download
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };  

  const handleDelete = async (fileName, isDirectory) => {
    try {
      if (isDirectory) {
        await api.delete('/storage/delete-directory', { data: { directoryName: `${currentPath}${fileName}/` } });
      } else {
        await api.delete(`/storage/delete-file`, { data: { fileName: `${currentPath}${fileName}` } });
      }
      fetchArquivos();
    } catch (error) {
      console.error('Erro ao deletar arquivo ou diretório:', error);
    }
  };

  // Navegar para dentro de um diretório
  const handleNavigate = (directoryName) => {
    setArquivos([]); // Limpa a listagem imediatamente
    setCurrentPath(`${currentPath}${directoryName}/`);
  };

  // Voltar ao diretório anterior
  const handleBack = () => {
    setArquivos([]); // Limpa a listagem imediatamente
    const pathParts = currentPath.split('/').filter(part => part !== ''); // Quebra o caminho em partes
    if (pathParts.length > 2) { // Sempre manter 'agah-arquitetura/private/' como base
      const newPath = pathParts.slice(0, -1).join('/') + '/';
      setCurrentPath(newPath);
    }
  };

  // Dropzone para o upload de arquivos
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => setFilesToUpload(prevFiles => [...prevFiles, ...acceptedFiles]), // Adiciona novos arquivos à lista
    multiple: true,
  });

  const relativePath = currentPath.replace('agah-arquitetura/private/', '');

  return (
    <div className="p-8">
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Pesquisar arquivos..."
          className="border rounded p-2 flex-grow mr-4"
        />
        <button
          className="border-2 border-white text-white bg-base font-bold py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out"
          onClick={() => setShowModal(true)}
        >
          Upload Arquivo
        </button>
      </div>

      <ListComponentArmazenamento
        items={arquivos}
        title={relativePath !== '' ? `Arquivos em: ${relativePath}` : 'Arquivos'}
        onDelete={(item) => handleDelete(item.nome, item.isDirectory)}
        onNavigate={handleNavigate}
        onBack={handleBack} // Aqui está a função de voltar
        showBackButton={currentPath !== 'agah-arquitetura/private/'} // Exibe o botão de voltar apenas se não estiver na raiz
        onDownload={handleDownload}
      />

      {showModal && (
        <ModalBase title={isCreatingDirectory ? "Criar Diretório" : "Upload de Arquivo"} onClose={() => setShowModal(false)}>
          <div className="mb-4">
            <div className="flex justify-between mb-4">
              <button
                className={`bg-${isCreatingDirectory ? 'gray-300' : 'base'} text-white py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out`}
                onClick={() => setIsCreatingDirectory(false)}
              >
                Upload Arquivos
              </button>
              <button
                className={`bg-${isCreatingDirectory ? 'base' : 'gray-300'} text-white py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out`}
                onClick={() => setIsCreatingDirectory(true)}
              >
                Criar Diretório
              </button>
            </div>

            {!isCreatingDirectory ? (
              <form onSubmit={handleUpload} className="mb-4">
                <div {...getRootProps({ className: 'dropzone border-dashed border-2 border-gray-400 p-6 rounded-lg' })}>
                  <input {...getInputProps()} />
                  <p>Arraste os arquivos aqui ou clique para selecionar arquivos</p>
                  
                  {/* Listagem de arquivos dentro da Dropzone */}
                  {filesToUpload.length > 0 && (
                    <ul className="mt-4">
                      {filesToUpload.map((file, index) => (
                        <li key={file.path} className="flex justify-between items-center">
                          <span>{file.path}</span>
                          <button
                            type="button"
                            onClick={(e) => handleRemoveFile(index, e)} // Impede a propagação do evento
                            className="text-red-500 hover:text-red-700 ml-2"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {isLoading && (
                  <p className="text-center text-blue-500 mt-4">Enviando arquivos...</p>
                )}

                <button
                  type="submit"
                  className="bg-base text-white py-2 px-4 rounded mt-4 hover:bg-white hover:text-base transition duration-200 ease-in-out"
                  disabled={isLoading} // Desabilita o botão de upload durante o carregamento
                >
                  Upload
                </button>
              </form>
            ) : (
              <form onSubmit={handleDirectoryCreation} className="mb-4">
                <input
                  type="text"
                  placeholder="Nome do Diretório"
                  value={directoryName}
                  onChange={(e) => setDirectoryName(e.target.value)}
                  className="border p-2 w-full mb-4"
                />
                <button
                  type="submit"
                  className="bg-base text-white py-2 px-4 rounded hover:bg-white hover:text-base transition duration-200 ease-in-out"
                >
                  Criar Diretório
                </button>
              </form>
            )}
          </div>
        </ModalBase>
      )}
    </div>
  );
};

export default PainelArmazenamento;
